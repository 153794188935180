@import 'app/variables.scss';

.Router {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Router__error,
.Router__spinner {
  align-self: center;
  margin: auto;
}

.Router__error {
  max-width: $aleph-screen-sm-max-width;
  padding: $aleph-content-padding;
  text-align: center;
}
