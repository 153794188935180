@import 'app/variables.scss';
@import 'app/mixins.scss';

$preview-padding: $aleph-grid-size * 1.5;

.EntityPreview {
  &__content {
    padding: $preview-padding;
    overflow-y: scroll;
    height: 100%;
  }

  &__overlay-container .#{$bp-ns}-overlay.#{$bp-ns}-overlay-container {
    @include rtl(right, 0, auto);
    @include rtl(left, auto, 0);
    overflow: visible;
  }

  .#{$bp-ns}-drawer-header {
    padding: 0 $aleph-grid-size * 0.5;
    box-shadow: 0 1px 2px rgba(16, 22, 26, 0.15);

    .#{$bp-ns}-heading {
      margin: 0 !important;
    }
  }

  td.entity {
    width: 40%;
  }
}
